@import '../../../UI/global.module.scss';

.container {
	width: 1440px;
	margin: 0 auto;
  padding: 88px 56px;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 64px;

	.header {
		display: flex;
		flex-direction: column;
		gap: 24px;
	}

  .title {
		font-size: 48px;
		font-weight: 400;
		line-height: 55px;
		letter-spacing: 1.2px;
		text-align: center;
  }
	
	.containerCards {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		gap: 24px;
	}

	.button {
		height: 54px !important;
	}

	a {
		width: 420px !important;
	}
}

.mainColor {
	color: $main-color;
}

@media only screen and (max-width: 768px) {
	.container {
		padding: 32px 20px 84px;
		width: 100%;
		gap: 40px;
		
		.header {
			gap: 16px;
		}

		.title {
			font-size: 30px;
			line-height: 110%;
			letter-spacing: 0.6px;
		}
		
		.subtitle {
			font-size: 16px;
			line-height: 22px;
		}

		.containerCards {
			display: grid;
			grid-template-columns: 1fr;			
			padding: 0;
			gap: 32px;
		}

		.button {
			height: 48px !important;
		}

		a {
			width: 100% !important;
		}
  }
}
